@import "../../common.scss";

.main_container {
  font-family: Poppins, Regular;
  box-sizing: border-box;
  margin: 0;

  .joinus_content {
    margin-top: 9.375em;
    margin-left: 8.75em;
    margin-right: 8.75em;
    .contact_form {
      display: flex;
      justify-content: space-between;
      .text {
        width: 46em;
        margin-top: 2.5em;
        // margin-top: 9.375em;
        // margin-left: 8.75em;
        // margin-right: 5em;
        .title {
          h1 {
            font-family: Baskerville, Regular;
            font-size: 2.5em;
          }
        }
        .description {
          margin-top: 3.125em;
          .description_content {
            // font-size: 1.1em;
            font-size: 1.5em;
            line-height: 1.9em;
            color: #58595b;
            li {
              list-style-type: disc;
              margin-top: 1em;
              margin-left: 1em;
            }
          }
        }
      }
      .form {
        width: 100%;
        max-width: 42em;
        margin-left: 8.625em;
        .form_flex {
          .form_field_customwidth {
            max-width: 100%;
            padding: 1em 0;
            .format_size_flex {
              max-width: 70%;
              display: flex;
              justify-content: space-between;
            }
            .cta_wrapper {
              margin: 0.688rem 0 0;
            }
            .upload_heading {
              color: $color-A7A7A7;
            }
          }
        }
      }
      .mobile_number_box {
        padding: 1em 0;
        .country_code {
          .countrycode_select {
            -webkit-appearance: none;
            outline: none;
            border: none;
            display: block;
            width: 100%;
            font-size: 1.188em;
            line-height: 1.2em;
            padding: 1.263em 2.579em 0.8em 1em;
            background-color: transparent !important;
            border-bottom: 1px solid $color-000000;
            color: $color-A7A7A7;
            appearance: none;
          }
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .main_container {
    .joinus_content {
      margin: 3em 5em 0;
      .joinus_container {
        width: 100%;
        display: inline-block;
        .text {
          width: auto;
          margin: 1em;
          text-align: center;
        }
        .form {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .main_container {
    .joinus_content {
      margin: 3em 1.5em 0;
      .contact_form {
        flex-direction: column;
        .text {
          width: 100%;
          margin-top: 3em;
        }
        .description {
          .description_content {
            font-size: 1.3em;
            li {
              margin-left: 1.5em;
            }
          }
        }
        .form {
          margin-top: 1.438em;
          width: 100%;
          max-width: 100%;
          margin-left: 0;

          .form_flex {
            margin: 3.5em 0;
          }
          .format_size_flex {
            max-width: 100% !important;
            p {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .main_container {
    .joinus_content {
      margin: 3em 1.5em 0;
      .contact_form {
        flex-direction: column;
        .text {
          width: 100%;
          margin-top: 3em;
        }
        .form {
          margin-top: 1.438em;
          width: 100%;
          max-width: 100%;
          margin-left: 0;

          .form_flex {
            margin: 3.5em 0;
          }
          .format_size_flex {
            max-width: 100% !important;
            p {
              font-size: 1.2em;
            }
          }
        }
      }
    }
  }
}
