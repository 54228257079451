@import "../../common.scss";

.thank_container {
    padding-top: 16.38em;
    padding-bottom: 9.38em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.188em;
    .received_img {
        width: 100%;
        max-width: 40.375em;
        height: 36.75em;
    }
    .received_title_text {
        font-size: 2.5em;
    font-family: "Libre Baskerville";
    color: #000000;
    margin-bottom: 0.425em;
    }
    .received-text {
        font-family: "Poppins";
        font-size: 1.25em;
        margin-bottom: 2.5em;
        width: 100%;
        max-width: 17em;
        line-height: 1.8;
        color: #58595B;
    }
    .received-btn {
        border: none;
        background-color: #fff;
        font-size: 1.225em;
        font-weight: 500;
    }
    .received-btn-text {
        text-transform: initial;
    }
}

@media (max-width: 767px) {
    .thank_container {
        flex-direction: column;
        align-items: start;
        gap: 0;
        padding-top: 13em;
        padding-bottom: 6.4em;
        .received_title_text {
            font-size: 2.2em;
            margin-top: 1.57em;
        }
        .received-text {
            font-size: 1.6em;
        }
        .received-btn {
            font-size: 1.145em;
        }
    }
  
}