@import "../../common.scss";

.bannersec {
  position: relative;
  // background: #dc7236;
  .banner-link-wrapper {
    background-color: $color-FFFFFF;
    position: absolute;
    left: 0;
    bottom: 0;
    box-shadow: 0px 0.4px 0.4px rgba($color: $color-000000, $alpha: 0.4);
    padding: 2.188em 6.375em 2.188em 5em;
    display: flex;
    align-items: center;
    z-index: 1;
    .banner-text-container {
      .banner-link-heading {
        font-family: "Poppins";
        font-weight: 500;
      }
      .banner-link-desc {
        font-family: "Poppins";
        font-weight: normal;
      }
    }
    .arrow-img {
      display: inline;
      margin-left: 6.875em;
      max-width: 4.125em;
    }
  }
  .swiper-pagination {
    position: absolute;
    top: 35%;
    bottom: 0;
    right: 0;
    left: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    display: none;
    .swiper-pagination-bullet {
      width: 100%;
      max-width: 2em;
      position: relative;
      display: flex;
      justify-content: center;
      padding: 1em 0.5em 2em;
      margin-bottom: 0.5em;
      border-radius: 0;
      height: 30px;
      line-height: 30px;
      font-size: 1.563em;
      font-weight: 600;
      color: rgba($color: $color-FFFFFF, $alpha: 0.5);
      opacity: 1;
      background: rgba(0, 0, 0, 0);
      transition: all 0.3ms ease-in;
    }
    .swiper-pagination-bullet-active {
      transition: all 0.3ms ease-in;
      color: #fff;
      font-weight: 500;
      // padding: 0.5em 1em;
      // font-size: 7.938em;

      &::before {
        margin-right: 0.05em;
        background-color: $color-DC7236;
        content: "";
        position: absolute;
        bottom: 0;
        display: block;
        height: 2px;
        max-height: 2px;
        width: 100%;
        max-width: 3em;
      }
    }
    .swiper-pagination-bullet-active:before {
      height: 3px;
    }
  }
}

.home_sec1 {
  margin: 9.375em 0;
  list-style-type: disc !important;
  .img_container {
    height: 75vh;
    width: 75vh;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .milestone-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 25em;
    .my_container {
      padding: 0;
    }
    .milestone-prev,
    .milestone-next {
      margin-top: 0.488em;
      max-width: 4.125em;
      cursor: pointer;
    }
    .milestone-extra-line {
      width: 100%;
      min-width: 12em;
      margin-top: 0.488em;
    }
    .milestone-box {
      text-align: center;
      .location_icon {
        margin-right: 0.38em;
        max-width: 0.74em;
      }
      .milestone-line {
        width: 100%;
        margin: 1.188em 0;
      }
      .milestone_heading {
        font-size: 1.25em;
        font-family: "Poppins";
        color: $color-000000;
        text-transform: uppercase;
      }
      .milestone_location {
        font-size: 1.25em;
        font-family: "Poppins";
        color: $color-000000;
        text-transform: uppercase;
      }
      .milestone_year {
        @include textStyles(2.5em, normal, 0);
        font-family: "Libre Baskerville Bold";
        font-weight: 600;
        color: $color-000000;
        text-transform: uppercase;
      }
    }
  }
}

.home_sec2 {
  .about-flex-box {
    align-items: center;
  }
  .counter_wrapper_flex {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    justify-content: flex-start;

    .about_heading {
      @include textStyles(2.5em, normal, 0);
      font-weight: bold;
      text-align: left;
      font-family: $heading-basker-regular;
      text-transform: uppercase;
      max-width: 70%;
    }

    .about_desc {
      // margin: 3.063em 0 4.063em;
      margin: 2.134em 0 1.867em;
      @include textStyles(1.25em, normal, 0);
    }

    .counter_cont {
      max-width: 45%;
      margin: 0em 1em 3em 0em;
      padding-right: 4em;
      min-width: 45%;
    }
  }
  .images_container {
    position: relative;
    .building1_wrapper {
      overflow: hidden;
      .building1_img {
        width: 100%;
        max-width: 45.375em;
        transition: all 0.2s ease-in-out;
        transform: scale(1.2);
        &:hover {
          transform: scale(1);
        }
      }
    }
    .building2_wrapper {
      position: absolute;
      bottom: -8em;
      right: -4em;
      .building2_img {
        width: 100%;
        max-width: 39.938em;
      }
    }
    .site_icon_wrapper {
      position: absolute;
      top: 6em;
      right: -14%;
      .site_icon {
        width: 100%;
        max-width: 12.375em;
        border-radius: 50%;
      }
    }
  }
}

.home_sec3 {
  background-color: $color-F9F9F9;
  margin-top: 8em;
  padding: 6.063em 0;
  // padding: 5em 0 6.063em;
  .feature_heading {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 1.5em;
    font-family: "Libre Baskerville Bold";
    font-weight: 600;
  }
  .col-lg-4 {
    &:nth-child(2) {
      .feature_box {
        margin-top: 0;
        border-radius: 15px;
        padding-bottom: 3em;
        .feature-img {
          top: -3.5em;
        }
      }
    }
  }
  .features_wrapper {
    position: relative;
    justify-content: center;
    .feature_box {
      text-align: center;
      margin: 8em 0.5em 3em;
      padding: 4.25em 2em 3em;
      border-radius: 15px;
      &:hover {
        background-color: $color-FFFFFF;
        box-shadow: 0px 3px 6px #0000001a;
      }
      .feature-img {
        position: absolute;
        top: 4em;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 6em;
        margin-bottom: 1.25em;
      }
      .feature-title {
        @include textStyles(1.563em, normal, 0);
        font-weight: 600;
      }
      .feature-desc {
        @include textStyles(1.125em, normal, 0);
        margin-top: 1.125em;
      }
    }
  }
}

.home_sec4 {
  position: relative;
  .ongoing_project_container {
    .ongoing_box {
      // align-items: center;
      .image_container {
        position: relative;
        .ongoing_project {
          width: 100%;
          // min-height: 46em;
          min-height: auto;
          max-height: 50em;
          object-fit: contain;
          max-width: 100%;
        }
      }
      .ongoing_proj_text {
        margin: auto 7em;
        // height: 50em;
        // max-height: 50em;
        .count {
          @include textStyles(1.25em, normal, 0);
          // margin: 0 0 1.688em;
          // color: $color-DC7236;
          margin: 0 0 0.688em;
          color: #000000;
        }
        .ongoing_heading {
          @include textStyles(2.5em, normal, 0);
          font-weight: 600;
          font-family: "Libre Baskerville Bold";
          color: #000000;
          text-transform: uppercase;
          opacity: 1;
        }
        .ongoing_desc {
          @include textStyles(1.25em, normal, 0);
          // margin: 2.063em 0 2.688em;
          margin: 1em 0 1em;
        }
        .span_desc {
          display: block;
          margin-top: 0.75em;
        }
        .ongoing_name {
          @include textStyles(1.875em, normal, 0);
          font-weight: bold;
          color: $color-DC7236;
        }
        .ongoing_location,
        .ongoing_area,
        .ongoing_space {
          @include textStyles(1.25em, normal, 0);
          // margin: 1em 0;
          margin: 0.5em 0;
          object-fit: contain;
          display: flex;
          .location_wrapper,
          .area_wrapper,
          .space_wrapper {
            min-width: 2em;
            max-width: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1em;
          }
          .location_icon {
            width: 100%;
            max-width: 1.4em;
            padding: 2px;
          }
          .area_icon {
            width: 100%;
            max-width: 1.806em;
            padding: 2px;
          }
          .space_icon {
            width: 100%;
            max-width: 1.805em;
            padding: 1px 0;
          }
        }
      }
    }
  }
  .arrow_container {
    position: relative;
    // top: 0;
    // right: 34%;
    // left: 1.2%;
    z-index: 2;
    // background-color: $color-FFFFFF;
    background-color: transparent;
    cursor: pointer;
    padding: 2em 6.25em;
    .arrows_wrapper {
      display: flex;
      // justify-content: space-between;
    }
    .ongoing-prev,
    .ongoing-next {
      margin: 0 0.5em;
      max-width: 3.75em;
      height: 2em;
    }
    .home-prev {
      rotate: 180deg;
    }
  }

  .cta_wrapper_project_desk {
    position: relative;
    z-index: 1;
    // left: 1%;
    // bottom: -4%;
    // background-color: #fff;
    background-color: transparent;
    width: 28em;
    // height: 9em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0.75em;
    margin-bottom: 2em;
    margin-top: 2em;
  }
}

.testimony_content_homepage {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../images/About/testimony_background.jpg");
  background-size: cover;

  .testimony_container {
    width: 100%;
    // height: 36em;

    padding: 3em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e1e1ee3;

    .testimony_info {
      position: relative;
      position: relative;
      width: 100%;
      max-height: 100%;
      text-align: center;
      color: white;
      .testimony_title {
        font-family: Baskerville, Regular;
        margin: 2.5em 0;
        width: 100%;
        h1 {
          font-size: 2.5em;
        }
      }
      .testimony_map {
        width: 100%;
        max-height: 100%;
        display: flex;
        overflow-y: auto;

        .testimony_data {
          .text {
            margin: 1.5em 2em;
            display: flex;
            justify-content: center;
            align-items: center;

            .testimony_description {
              width: 65em;
              margin: 0 8em;
              p {
                font-size: 1.2em;
                line-height: 25px;
                // color: rgba(211, 211, 211, 0.432);
                color: rgba(211, 211, 211, 0.8);
              }
            }
          }
        }
        .seperator {
          margin: 1.5em 0;
          width: 100%;
          display: flex;
          justify-content: center;
          .horizontal_line {
            width: 50px;
            height: 1px;
            background-color: #dc7236;
          }
        }

        .client_name {
          width: 100%;
          text-align: center;

          h6 {
            font-size: 1.12em;
          }
          p {
            margin: 0.3em 0;
            font-size: 0.9em;
          }
        }
      }

      .testimony_map::-webkit-scrollbar {
        display: none;
      }

      .arrow_container {
        width: 100%;
        position: absolute;
        top: 48%;
        z-index: 100;
        .arrows_wrapper {
          width: 100%;
          margin-bottom: 1em;
        }

        .arrow_desktop {
          img {
            width: 100%;
            max-width: 3.5em;
            cursor: pointer;
          }
          .leftarrowimg {
            margin-right: 75%;
          }
        }
      }
      .arrow_mobile {
        img {
          margin-top: 4%;
          width: 100%;
          max-width: 4em;
        }

        .leftarrowimg {
          margin-right: 5%;
        }
      }
    }
  }
}

@media (min-width: 1999px) {
  .home_sec2 {
    .images_container {
      .building1_wrapper {
        .building1_img {
          min-width: 53em;
          max-width: 53em;
        }
      }
      .building2_wrapper {
        right: -8em;
        .building2_img {
          width: 100%;
          min-width: 40.938em;
          max-width: 40.938em;
        }
      }
    }
  }
  .home_sec4 {
    .arrow_container {
      right: 37%;
    }
  }
}

@media (max-width: 1600px) {
  .home_sec1 {
    .milestone-wrapper {
      .milestone-extra-line {
        min-width: 12em;
        margin-top: 0.55em;
      }
    }
  }
}
@media (max-width: 1536px) {
  .home_sec1 {
    .milestone-wrapper {
      .milestone-extra-line {
        min-width: 12em;
        margin-top: 0.58em;
      }
    }
  }
}

@media (max-width: 1440px) {
  .home_sec1 {
    .milestone-wrapper {
      .milestone-extra-line {
        min-width: 12em;
        margin-top: 0.48em;
      }
    }
  }
}

@media (max-width: 1280px) {
  .home_sec1 {
    .milestone-wrapper {
      .milestone-extra-line {
        min-width: 12em;
        margin-top: 0.44em;
      }
    }
  }
}

@media (max-width: 1024px) {
  .home_sec1 {
    .milestone-wrapper {
      .milestone-extra-line {
        margin-top: 0;
      }
      .milestone-box {
        .milestone_heading {
          font-size: 1.1em;
        }
        .milestone_location {
          font-size: 1.1em;
        }
      }
    }
  }
  .home_sec4 {
    .ongoing_project_container {
      .ongoing_box {
        .image_container {
          .ongoing_project {
            max-height: 52.5em;
          }
        }
      }
    }
  }
}
@media (max-width: 834px) {
  .home_sec1 {
    .milestone-wrapper {
      .milestone-extra-line {
        margin-top: 0.2em;
      }
    }
  }
  .home_sec3 {
    .feature_heading {
      margin-bottom: 0;
    }
    .features_wrapper {
      .feature_box {
        background-color: #ffffff;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
      }
      .arrows_wrapper {
        margin-top: 1em;
        img {
          max-width: 3.5em;
          margin: 0 1em;
        }
      }
    }
  }

  .home_sec4 {
    .ongoing_project_container {
      .ongoing_box {
        .image_container {
          .ongoing_project {
            min-height: auto;
          }
        }
      }
    }
    .arrow_container {
      right: 3%;
      padding-left: 8.5em;
    }
  }
}

@media (max-width: 835px) {
  .home_sec2 {
    .images_container {
      margin-top: 4em;

      .building2_wrapper {
        bottom: -10em;
        right: 0;

        .building2_img {
          max-width: 45em;
        }
      }
      .site_icon_wrapper {
        right: 17%;
      }
    }
  }

  .home_sec3 {
    margin-top: 15em;
    .feature_heading {
      margin-bottom: 0;
    }
    .features_wrapper {
      .feature_box {
        background-color: #ffffff;
        box-shadow: 0px 3px 6px rgb(0 0 0 / 10%);
      }
      .arrows_wrapper {
        margin-top: 1em;
        img {
          max-width: 3.5em;
          margin: 0 1em;
        }
      }
    }
  }

  .home_sec4 {
    margin-top: 4em;
    .ongoing_project_container {
      .ongoing_box {
        .ongoing_proj_text {
          padding-bottom: 3em;
        }
        .image_container {
          .ongoing_project {
            max-height: 50em;
          }
        }
      }
    }
  }
  .image_container {
    float: left;
    margin: 0 7em;
  }
}

@media (max-width: 600px) {
  .bannersec {
    .swiper-pagination {
      right: 0;
      top: auto;
      bottom: 5em;
      left: 0;
      margin: 0 auto;
      flex-direction: row;
      justify-content: center;
    }
  }
  .home_sec1 {
    width: 100%;
    margin: 6em 0 7em;
    .milestone-wrapper {
      width: 72%;
      height: auto;
      // margin: 0 5.5em;
margin: 0 auto;
      .milestone-box {
        .milestone_year {
          font-size: 2.5em;
        }
        .milestone_heading {
          font-size: 2em;
          margin-bottom: 3px;
        }
        .milestone_location {
          font-size: 2em;
          .location_icon {
            max-width: 1em;
          }
        }
      }
      .milestone-extra-line {
        display: none;
      }
      .milestone-prev,
      .milestone-next {
        margin-top: 2.4em;
        max-width: 3.6em;
      }
    }
  }

  .home_sec2 {
    .counter_wrapper_flex {
      .about_heading {
        max-width: 100%;
      }

      .about_desc {
        font-size: 1.5em;
      }
    }

    .images_container {
      margin-top: 0;
      .building1_wrapper {
        .building1_img {
          max-width: 30em;
        }
      }
      .building2_wrapper {
        right: 0;
        .building2_img {
          max-width: 26em;
        }
      }
      .site_icon_wrapper {
        top: 4em;
        right: 2em;

        .site_icon {
          max-width: 8em;
        }
      }
    }

    .cta_wrapper_mob {
      width: 100%;
      margin-top: 13em;
      display: flex;
      justify-content: center;
    }
  }

  .home_sec3 {
    padding: 5em 0 5em;
    // height: 50em;
    margin-top: 5em;
    margin-bottom: 5em;
    
    .feature_heading {
      margin-bottom: 0;
    }

    .features_wrapper {
      .feature_box {
        height: 26em;
        padding: 4.25em 1.5em 3em;
        // margin: 8em 1.6em 3em;
        margin: 6.4em 1.6em 3em;
        .feature-img {
          max-width: 7em;
        }
        .feature-title {
          font-size: 2em;
          margin-top: 0.5em;
        }
        .feature-desc {
          font-size: 1.5em;
        }
      }
   
      .arrows_wrapper {
        // padding: 1.5em 0;
        width: 100%;
        max-width: 14em;
        // margin: 3em auto 0;
        margin: 0 auto 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        img {
          max-width: 3.5em;
          margin: 0 1em;
        }
      }
    }
  }

  .home_sec4 {
    margin-top: 4em;
    .ongoing_project_container {
      .ongoing_box {
        .image_container {
          margin: 3em 1.5em;
        }
        .ongoing_proj_text {
          margin: 0 1.5em;
          padding-bottom: 1.5em;
          .ongoing_name {
            font-size: 1.8em;
            text-transform: uppercase;
            margin-bottom: 0.445em;
          }
          .ongoing_location {
            font-size: 1.6em;
          }
          .count {
            margin: 0 0 0.96em;
          }
          .ongoing_desc {
            margin: 2.572em 0 2em;
            font-size: 1.4em;
          }
        }
      }
    }

    .arrow_container {
      position: absolute;
      top: auto;
      bottom: 5%;
      right: 18%;
      // top: 95em;
      // right: 17%;
      background-color: rgba(255, 255, 255, 0);
      .arrows_wrapper {
        img {
          margin: 0 1.5em;
          max-width: 3.75em;
          height: 3em;
        }
      }
    }
    .cta_wrapper_project_mob {
      width: 100%;
      display: flex;
      justify-content: center;
      // margin: 7em 0 5em;
      margin: 7em 0 5em;
    }
  }

  .testimony_content_homepage {
    height: auto;
    margin-top: 5em;
    .testimony_container {
      padding-top: 2.5em;
      .testimony_info {
        .testimony_map {
          .client_name h6 {
            font-size: 1.6em;
            opacity: 0.8;
            margin-bottom: 0.625em;
          }
          .client_name p {
            font-size: 1.4em;
            opacity: 0.7;
          }
          .testimony_data {
            .text {
              .testimony_description {
                margin: 0;
              }
            }
          }
        }
       
        .arrows_wrapper {
          margin: 3em 0 2em;
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .home_sec3 .features_wrapper .feature_box {
    height: 30em;
}
}