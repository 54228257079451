@import "../../common.scss";

// .new_breadcrumb_section {
//     padding-top:  7.8em;
//     .new_breadcrumb {
//         margin: 1.25em 0 0;
//         @include textStyles(1.125em, normal, 0);
//         font-weight: 500;
//         a {
//           color: $color-000000;
//         }
//       }
// }
.privacy-section {
    margin: 8.8em 0 4em;
  .heading-text {
    text-align: center;
    font-size: 2.5em;
    font-family: "Libre Baskerville";
    font-weight: bold;
    text-transform: uppercase;
    color: #000000;
    // margin-top: 6.25em;
    margin-top: 6.5em;
    margin-bottom: 2.575em;
  }
  .para-text {
    // margin-top: 5.15em;
    font-family: "Poppins";
    font-size: 1.25em;
    color: #000000;
    text-align: left;
  }
  .para-link {
    text-decoration: none;
    // color: #000000;
  }
  .sub-heading-text {
    font-size: 2.125em;
    font-family: "Poppins";
    color: #000000;
    margin: 2.06em 0 0.765em;
  }
  .privacy-list li {
    list-style: disc;
  }
  .list-para {
    margin: 1em 0 0;
    display: block;
    // color: #000000;
  }
  .socialmedia-text {
    display: block;
    font-weight: 700;
    // opacity: 0.6;
    margin: 1em 0 0;
    color: #000000;
    letter-spacing: 0.3px;
  }
}

@media (max-width: 600px) {
  .privacy-section {
    .heading-text { 
      margin-top: 5.25em;
    }
    .privacy-list li {
      margin-left: 1.15em;
    }
  }
}
