.my_container {
  .faqs_content {
    .title {
      width: 100%;
      margin-top: 6.4em;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-family: Baskerville, Regular;
        font-size: 2.5em;
        font-weight: 600;
      }
    }

    .faq_title {
      font-size: 1.5em;
      margin-top: 5em;
      color: #DC7236;
      font-weight: 600;
    }

    .segment {
      font-size: 1em;
    }

    .div_line {
      width: 100%;
      height: 1px;
      background-color: #cbcbcb;
      margin-top: 2em;
    }

    .accordion-header {
      position: relative;
      width: 100%;
      margin: 1em 0;

      .accordion-button .collapsed {
        .icon {
          position: absolute;
          right: 0;
          width: 100%;
          max-width: 17px;
        }
      }

      .accordion-button {
        width: 90%;
        display: flex;
        align-items: center;
        width: 100%;
        font-family: Poppins, Medium;
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        letter-spacing: 0.48px;
        color: #1a1818;
        background-color: white;
        border: none;

        p {
          width: 85%;
        }

        .icon {
          transform: rotate(180deg);
          transition: 0.3s ease-in-out;
          position: absolute;
          right: 0;
          max-width: 17px;
        }
      }
    }

    .accordion-body {
      width: 100%;
      max-width: 90%;
      text-align: left;
      margin-left: 1em;
      font-size: 14px;
      letter-spacing: 0.4px;
      overflow: auto;
      padding-bottom: 1em;
      &::-webkit-scrollbar {
        height: 4px;
      }
      .faq20 {
        margin-left: 2em;
        li {
          list-style-type: lower-alpha !important;
        }
      }

      .faq6_table {
        border-spacing: 10px;
        th,
        td {
          padding: 2px 20px;
          margin: 1em;
        }
        .table_heading {
          border-top: 1px solid black;
          border-bottom: 1px solid black;
        }
        .table_subheading {
          border-bottom: 1px solid black;
        }
        .islandchange {
          border-right: 1px solid rgb(186, 186, 186);
        }

        .s-row,
        .fo-row,
        .si-row {
          background-color: #cbcbcb;
        }
      }
    }

    .disclaimer {
      margin-top: 10em;
      h5 {
        font-size: 1em;
        font-weight: 500;
      }
      p {
        font-size: 0.8em;
      }
    }
  }
}

@media (max-width: 600px) {
  .my_container {
    .faqs_content {
      .accordion-body {
        margin: 0;
      }
    }
  }
}
