.my_container{

    .title{
        width: 100%;
        margin-top: 6.4em;
        margin-bottom: 2.375em;
        display: flex;
        justify-content: center;
        align-items: center;
        h1{
            font-family: Baskerville, Regular;
            font-size: 2.5em;
        }
    }

    .guide_content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 3em;
        margin-bottom: 4em;

        .guide{
            margin-bottom: 1.5em;
            .image{
                width: 100%;
                max-width: 45em;

                img{
                    max-width: 100%;
                }
            }

            .guide_name{
                margin-top: 1.2em;
                .guide_text{
                    font-size: 1.2em;
                }
            }

            // .knowmore{
            //     margin-top: 0.6em;
            //     max-width: 100%;
            //     cursor: pointer;
            //     .knowmore_text{
            //         font-size: 1.3em;
            //         color: #DC7236;

            //         .arrow{
            //             margin-left: 2.5em;
            //             width: 100%;
            //             max-width: 1em;
            //         }
            //     }
            //     .knowmore_text:hover{
            //         color: #ff6105;
                    
            //     }
                
            //     .underline{
            //         width: 12em;
            //         height: 1px;
            //         background-color: #DC7236;;
            //     }
            // }
        }
    }
}

@media (max-width: 600px){
    .my_container{
        .guide_content{
            .guide{
                .guide_name{
                    .guide_text{
                        font-size: 1.5em;
                    }
                }
            }
        }
    }
}