@import "../../common.scss";

.main_container {
  font-family: Poppins, Regular;
  box-sizing: border-box;
  margin: 0;
}
.partner_sec1 {
  .gallery_container {
    position: relative;
    margin-right: 0;
    padding-right: 0;
  }
  .accordion_sec {
    .categories {
      padding: 9.375em 0;
      .partner_heading {
        @include textStyles(2.5em, normal, 0);
        font-family: "Libre Baskerville";
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 0 2.813rem;
      }
      .partner {
        @include textStyles(1.563em, 1.179em, normal);
        @include fontStyles("Work Sans", 500);
        margin-top: 1.036em;
        text-transform: uppercase;
        opacity: 0.8;
        cursor: pointer;
        width: 100%;
        // max-width: 80%;
        max-width: 67%;
        transition: 0.3s all;
        .orange_arrow {
          position: absolute;
          right: 2em;
          opacity: 0;
        }
        .orange_arrow.active {
          opacity: 1;
        }
      }
      .partner:nth-child(1) {
        margin-top: 0;
      }
      .partner.partner_active {
        position: relative;
        opacity: 1;
        transition: 0.3s all;
        color: $color-DC7236;
      }
    }
    .contact_form {
      .text {
        width: 100%;
        margin-right: 8em;
        .heading {
          h1 {
            font-family: Baskerville, Regular;
            font-size: 2.5em;
          }
        }
        .info {
          p {
            width: 34.5em;
            font-family: Poppins, Regular;
            font-size: 1.25em;
            margin: 3em 0;
            line-height: 25px;
            color: #58595b;
          }
        }
      }
      .form {
        .form_flex {
          .form_field_customwidth {
            max-width: 100%;
            padding: 1em 0;
            .format_size_flex {
              max-width: 70%;
              display: flex;
              justify-content: space-between;
            }
            .cta_wrapper {
              margin: 0.688rem 0 0;
            }
            .upload_heading {
              color: $color-A7A7A7;
            }
          }
        }
      }
    }
    .form {
      margin-left: 5em;
      padding: 9.375em 8.75em;
      background-color: $color-F9F9F9;
    }
  }
}

@media (max-width: 834px) {
  .main_container {
    .mb_categories_accordion_wrapper {
      text-align: left;
      .partner_heading {
        @include textStyles(2.2em, normal, 0);
        font-family: "Libre Baskerville";
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 1em;
      }
      .mb_categories_accordion_title {
        @include textStyles(2em, 1.179em, normal);
        @include fontStyles("Work Sans", 500);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-transform: uppercase;
        opacity: 0.8;
        cursor: pointer;
        padding-top: 0.8em;
        padding-bottom: 0.8em;
        span {
          padding-right: 1.6em;
        }
      }
      .mb_categories_cont:nth-child(1) .mb_categories_accordion_title {
        padding-top: 0;
      }
      .mb_categories_cont:last-child .mb_categories_accordion_title {
        border-bottom: 0;
      }
      .mb_categories_accordion_title.active {
        opacity: 1;
        transition: 0.3s all;
        color: $color-DC7236;
        border-bottom: 0;
      }
      .mb_categories_down_arrow {
        position: absolute;
        right: 1em;
        width: 0.5em;
        transform: rotate(90deg);
      }
      .mb_categories_down_arrow.active {
        // width: 1em;
        width: 0.857em;
        transform: none;
        right: 0.8em;
      }
    }
    .mb_categories_accordion_content {
      display: none;
    }
    .mb_categories_accordion_content.active {
      display: block;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mb_categories_cont:last-child .mb_categories_accordion_content.active {
      border-bottom: 0;
    }
  }
  .partner_sec1 {
    margin: 5.6em 0;
    .gallery_container {
      position: relative;
      margin: 0 auto;
      padding: 0 15px;
    }
    .accordion_sec {
      .form {
        padding: 0 15px 4.1em;
        margin: 0 -15px;
        background-color: $color-F9F9F9;
      }
    }
  }
}

@media (max-width: 600px) {
  .main_container {
    .mb_categories_accordion_wrapper {
      .partner_heading {
        font-size: 2.5em;
      }
    }
  }
  .partner_sec1 {
    margin: 0;
  }
}

@media (max-width: 420px) {
  .main_container {
    .channelpartners_container {
      .channelpartners_content {
        .form {
          margin-top: 1.438em;
          width: 100%;
          .form_flex {
            margin: 3.5em 1.5em;
          }
        }
      }
    }
  }
}
