@import "../../common.scss";

.main_container {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  .contact_container {
    margin-top: 9.375em;
    margin-left: 8.75em;
    margin-right: 8.75em;

    .contact {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .contact_form {
        width: 42em;
        margin-right: 8.625em;

        .text {
          width: 100%;
          margin-right: 8em;
          .heading {
            h1 {
              font-family: Baskerville, Regular;
              font-size: 2.5em;
            }
          }
          .info {
            p {
              width: 34.5em;
              font-family: Poppins, Regular;
              font-size: 1.25em;
              margin: 3em 0;
              line-height: 25px;
              color: #58595b;
            }
          }
        }
        .form {
          .form_flex {
            .form_field_customwidth {
              max-width: 100%;
              padding: 1em 0;
              .format_size_flex {
                max-width: 70%;
                display: flex;
                justify-content: space-between;
              }
              .cta_wrapper {
                margin: 0.688rem 0 0;
              }
              .upload_heading {
                color: $color-A7A7A7;
              }
            }
          }
        }
      }

      .map_content {
        width: 51.75em;
        height: 56.688em;
        .map {
          width: 100%;
          height: auto;
          .google_map {
            width: 100%;
            max-width: 51.75rem;
            min-height: 56.688em;
          }
        }
      }
    }
    .mobile_number_box {
      padding: 1em 0;
      .country_code {
        .countrycode_select {
          -webkit-appearance: none;
          outline: none;
          border: none;
          display: block;
          width: 100%;
          font-size: 1.188em;
          line-height: 1.2em;
          padding: 1.263em 2.579em 0.8em 1em;
          background-color: transparent !important;
          border-bottom: 1px solid $color-000000;
          color: $color-000000;
          appearance: none;
        }
      }
    }
    .country_location_box {
      position: relative;
      .country_code {
        .countrycode_select {
          cursor: pointer;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
          border: none;
          display: block;
          width: 100%;
          font-size: 1.188em;
          line-height: 1.2em;
          padding: 1.263em 2.579em 0.8em 1em;
          background-color: transparent !important;
          border-bottom: 1px solid $color-000000;
          color: $color-000000;
        }
      }
      .dropwdown_icon {
        position: absolute;
        // top: 2.1em;
        top: 50%;
        right: 5%;
        max-width: 1em;
      }
    }
  }
  .support_container {
    background-image: url("../../images/Contact/contact_background.png");
    background-size: cover;
    background-position: center;
    width: auto;
    margin-top: 9.375em;
    .support_content {
      width: 100%;
      height: 37.813em;
      background-color: #1e1e1ee0;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      .support {
        text-align: center;
        .heading {
          h1 {
            font-family: Baskerville, Regular;
            font-size: 2.5em;
          }
          p {
            font-family: Poppins, Regular;
            font-size: 1.25em;
            margin-top: 3em;
          }
        }

        .support_info {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 5em;
          margin-left: 8.75em;
          margin-right: 8.75em;

          .contact_info {
            width: 33.5em;
            .icon {
              display: flex;
              justify-content: center;
              img {
                width: 5em;
                height: 5em;
              }
            }
            .text {
              .heading {
                margin-bottom: 1.875em;
                p {
                  font-size: 1em;
                  margin: 0;
                }
              }
              .info {
                p {
                  font-size: 1.25em;
                  margin: 0 5em;

                  a {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 834px) {
  .main_container {
    .contact_container {
      .contact {
        display: inline-block;
        .contact_form {
          width: 100%;
          .text {
            text-align: center;
            .heading {
              h1 {
                font-size: 2.5em;
              }
            }
            .info {
              p {
                max-width: 100%;
                font-size: 1.7em;
                font-weight: 400;
                margin: 1.875em 0;
                color: #000;
              }
            }
          }
          // .form {
          // }
        }

        .map_content {
          width: 100%;
          height: 100%;
          .map {
            img {
              max-width: 100%;
            }
          }
        }
      }
    }
    .support_container {
      .support_content {
        width: 100%;
        height: 100%;
        .support {
          margin: 3.2em 0;
          .heading {
            margin: 0 5.125em;
            h1 {
              font-size: 2.5em;
              font-weight: 700;
            }
            p {
              margin-top: 1em;
              font-size: 1.7em;
            }
          }
          .support_info {
            display: inline-block;
            margin: 1em 0 0 0;
            .contact_info {
              margin-top: 2em;
              .icon {
                img {
                  width: 8em;
                  height: 8em;
                }
              }
              .text {
                .heading {
                  p {
                    font-size: 1.5em;
                  }
                }
                .info {
                  p {
                    font-size: 1.7em;
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .main_container {
    .contact_container {
      margin-top: 5em;
      margin-left: 1.5em;
      margin-right: 1.5em;
      .country_location_box {
        .country_code {
          .countrycode_select {
            font-size: 1.4em;
          }
        }
      }
      .contact {
        .contact_form {
          .text {
            .info p {
              margin: 1.8825em 0 1.6471em;
            }
          }
        }
        .map_content {
          margin-top: 3.5em;
          height: 30em;
        }
      }
    }
    .support_container {
      .support_content {
        .support {
          margin: 5em 0;
          .heading h1 {
            font-size: 2.2em;
          }
          .heading p {
            font-size: 1.6em;
          }
          .support_info {
            .contact_info {
              .text {
                .info p {
                  font-size: 1.6em;
                }
              }
            }
          }
        }
      }
      margin-top: 3.5em;
    }
  }

  .form_field_customwidth {
    .upload_heading {
      font-size: 1.2em;
      margin-left: 1em;
    }
  }

  .form_field_wrapper {
    .upload_label {
      max-width: 100% !important;
    }
  }

  .format_size_flex {
    max-width: 100% !important;
    p {
      font-size: 1.2em;
    }
  }

  .contact_form {
    .text {
      .heading {
        h1 {
          font-weight: 600;
        }
      }
    }
    .form {
      .form_flex {
        .form_submit {
          margin-top: 1.2em;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .map_content {
    .map {
      height: 40em !important;

      .google_map {
        min-height: 100% !important;
      }
    }
  }

  @media (max-width: 400px) {
    .mobile_number_box {
      .country_code {
        .countrycode_select {
          padding-left: 0.5em !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}
