@import "../../common.scss";
.right_arrow_cta_flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: fit-content;
  cursor: pointer;
  padding-bottom: 0.7em;
  &::after {
    content: "";
    position: absolute;
    bottom: 0em;
    left: 0;
    width: 100%;
    height: 2.5px;
    background-color: $color-DC7236;
    border: 0;
    outline: 0;
    transition: all 0.3s;
  }
  &:hover {
    &::after {
      width: 125%;
    }
  }
  .cta_text {
    @include textStyles(1.125em, normal, 0.9px);
    color: $color-DC7236;
    text-transform: uppercase;
    white-space: nowrap;
    transition: 0.3s color;
  }
  .cta_right_arrow {
    box-sizing: content-box;
    width: 100%;
    max-width: 2.188em;
    padding-left: 1.375em;
  }
  &.apply_hover:hover {
    .cta_text {
      color: $color-DC7236;
    }
  }
  &.apply_hover:hover {
    &::after {
      background-color: $color-DC7236;
    }
  }
}
.right_arrow_cta_flex.cta_white {
  &::after {
    background-color: $color-FFFFFF;
  }
  .cta_text {
    color: $color-FFFFFF;
  }
}
@media (max-width: 767px) {
  .right_arrow_cta_flex {
    padding-bottom: 1.2em;
    &::after {
      width: 100%;
    }
    .cta_text {
      font-size: 1.4em;
    }
  }
}
