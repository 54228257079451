@import "../../common.scss";
.faq_item {
  padding: 1.5rem 1.875em 1.5rem 0;
  // padding-right: 12.5em;
  padding-right: 7.5em;
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid $color-000000;
  .faq_title {
    font-size: 1.5em;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 1.5em;
    color: $color-58595B;
    text-transform: uppercase;
  }
  .faq_desc {
    font-size: 1.3em;
    line-height: 1.6em;
    color: $color-58595B;
    height: 0;
    overflow: hidden;
    // transition: transform 0.3s ease-out; // note that we're transitioning transform, not height!
    // transform: scaleY(0); // implicit, but good to specify explicitly
    // transform-origin: top;
    li {
      position: relative;
      padding-left: 1em;
      &::before {
        content: "";
        position: absolute;
        top: 0.6em;
        left: 0em;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $color-000000;
      }
    }
  }
  .faq_desc.active {
    margin-top: 1em;
    height: auto;
    // transform: scaleY(1);
  }
  .faq_open_close_logo {
    position: absolute;
    width: 100%;
    max-width: 1.625rem;
    top: 2.875em;
    right: 0;
  }
}
.faq_item.active {
  box-shadow: none;
  .faq_title {
    color: $color-DC7236;
  }
}
.faq_item:first-child {
  padding: 0 1.875em 1.5rem 0;
  .faq_open_close_logo {
    top: 0em;
  }
}

@media (max-width: 767px) {
  .faq_item {
    padding-right: 0;
  }
}
