.my_container{
    .faqs_content{
        .title{
            width: 100%;
            margin-top: 6.4em;
            margin-bottom: 2.375em;
            display: flex;
            justify-content: center;
            align-items: center;
            h1{
                font-family: Baskerville, Regular;
                font-size: 2.5em;
            }
        }

            .div_line{
                width: 100%;
                height: 1px;
                background-color: #cbcbcb;
                margin-top: 2em;
            }

            .accordion-header{
                position: relative;
                width: 100%;
                margin: 1em 0;

                .accordion-button .collapsed  {
                        .icon{
                            
                            position: absolute;
                            right: 0;
                            width: 100%;
                            max-width: 17px;
                        }
                }

                .accordion-button.collapsed{
                    .icon{
                        transform: rotate(0deg);
                    }
                }

                .accordion-button{
                    width: 90%;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    font-family: Poppins, Medium;
                    font-size: 17px;
                    font-weight: 500;
                    text-align: left;
                    letter-spacing: 0.48px;
                    color: #1A1818;
                    background-color: white;
                    border: none;

                    p{
                        width: 85%;
                    }

                    .icon {
                        transform: rotate(180deg);
                        transition: .3s ease-in-out;
                        position: absolute;
                        right: 0;
                        max-width: 17px;
                    }
                    
                }
            }

            .accordion-body{
                font-family: Poppins, regular;
                width: 90%;
                text-align: left;
                margin-left: 1em;
                font-size: 14px;
                letter-spacing: 0.4px;
            }
    }
}