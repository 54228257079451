@import "../../common.scss";
// .header {
//   background-color: #000;
// }
// .navbar_bg {
//   background-color: #fff;
// }
.inner_sec1 {
  position: relative;
  // padding-top: 7.8em;
  padding-top: 9.8em;
  position: relative;
  .my_breadcrumb {
    margin: 1.25em 0 0;
    @include textStyles(1.125em, normal, 0);
    font-weight: 500;
    a {
      color: $color-000000;
    }
    .status-text {
      text-transform: capitalize;
    }
  }
  .navigate_tabs {
    margin: 4.563em 0 4.938em;
    background-color: $color-FFFFFF;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    padding-bottom: 1em;
    &::-webkit-scrollbar {
      height: 4px;
    }
    .nav_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      .nav_link {
        @include textStyles(1.875em, normal, 0);
        text-transform: uppercase;
        margin: 0 1.067em 0 0;
        font-weight: 500;
        cursor: pointer;
      }
      .nav_link.active {
        color: $color-DC7236;
        border-bottom: 3px solid $color-DC7236;
      }
    }
  }
}

.inner_sec2 {
  margin: 0 0 7.813em 0;
  .text_container {
    width: 100%;
    max-width: 70%;
    .project_name {
      @include textStyles(2.5em, normal, 0);
      text-transform: uppercase;
      font-family: "Libre Baskerville";
      font-weight: 600;
      margin: 2em 0 0 0;
    }
    .project_desc {
      @include textStyles(1.25em, normal, 0);
      margin: 2em 0;
      color: $color-58595B;
      font-weight: normal;
    }
    .span_desc {
      display: block;
      margin-top: 1.25em;
    }
    .cta_wrapper {
      margin: 0 0 3.75em 0;
      background-color: #dc7236;
      display: inline-block;
      padding: 1em 2.5em 1em 2.5em;
      .right_arrow_cta_flex {
        .cta_right_arrow {
          padding-left: 3.375em;
        }
        &::after {
          background-color: #ffffff;
          height: 1px;
        }
        .cta_text {
          text-transform: capitalize;
          color: #ffffff;
          font-size: 1.5em;
        }
        // .right_arrow_cta_flex {
        //   &:hover::after {
        //       width: 105%;
        //   }
        // }
      }
      &:hover {
        ::after {
          width: 110%;
        }
      }
    }
    .highlights_heading {
      @include textStyles(1.875em, normal, 0);
      text-transform: uppercase;
      font-weight: 500;
      color: $color-DC7236;
      margin: 0 0 1em 0;
    }
    .project_feature {
      @include textStyles(1.25em, normal, 0);
      // text-transform: capitalize;
      margin: 0 0 1.2em 0;
      position: relative;
      .project_feature_content {
        margin-left: 2.2em;
      }
      .location_icon {
        margin: 0 1.25em 0 0;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .image_wrapper {
    position: relative;
    .project_image {
      width: 100%;
      max-width: 45.375em;
    }
    .circl_wrapper {
      position: absolute;
      top: 5.5em;
      right: 0;
    }
  }
}

.inner_sec3 {
  background-color: $color-F9F9F9;
  padding: 6.25em 0;
  .gallery_container {
    position: relative;
    margin-right: 0;
    padding-right: 0;
    .arrows_wrapper {
      position: absolute;
      top: 0;
      right: 8.813em;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        width: 100%;
      }
      .gallery-prev {
        margin-right: 3.937em;
        cursor: pointer;
        max-width: 3.75em;
      }
      .gallery-next {
        cursor: pointer;
        max-width: 3.75em;
      }
    }
  }

  .gallery_heading {
    @include textStyles(2.5em, normal, 0);
    font-family: "Libre Baskerville";
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 2.813rem;
  }

  .image_wrapper {
    position: relative;
    &:before {
      content: "";
      display: none;
      position: absolute;
      height: 0%;
      width: 100%;
      bottom: 0;
      transition: height 0.3s ease-out;
      background: linear-gradient(to bottom, transparent 0%, black 100%);
    }
    .title_text {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      // font-size: 1.875em;
      // font-family: "Heebo";
      font-size: 1.5em;
      font-family: "Poppins";
      text-transform: uppercase;
      // color: $color-DC7236;
      font-weight: 500;
      color: #ffffff;
      background-color: rgb(0, 0, 0, 0.6);
      padding: 0.25em 1em;
      z-index: 2;
      transition: 0.3s ease-in-out;
    }
    .imageanimate {
      overflow: hidden;
      .gallery_img {
        width: 100%;
        max-height: 51.563em;
        transform: scale(1.1);
        transition: 0.3s ease-in-out;
        &:hover {
          transform: scale(1);
        }
      }
    }
  }
  .image_wrapper:hover {
    &:before {
      height: 40%;
      z-index: 1;
      opacity: 0.9;
    }
    .gallery_img {
      transform: scale(1);
    }
    // .title_text {
    //   display: block;
    // }
  }
}

.inner_sec4 {
  position: relative;
  .ongoing_project_container {
    .ongoing_box {
      align-items: center;
      .image_container {
        position: relative;
        .ongoing_project {
          width: 100%;
        }
      }
      .ongoing_proj_text {
        margin: auto 7em;
        .count {
          @include textStyles(1.25em, normal, 0);
          margin: 0 0 1.688em;
          color: $color-DC7236;
        }
        .ongoing_heading {
          @include textStyles(2.5em, normal, 0);
          font-weight: 600;
          font-family: "Libre Baskerville Bold";
          color: #000000;
          text-transform: uppercase;
          opacity: 1;
        }
        .ongoing_desc {
          @include textStyles(1.25em, normal, 0);
          margin: 2.063em 0 2.688em;
        }
        .ongoing_name {
          @include textStyles(1.875em, normal, 0);
          font-weight: bold;
          color: $color-DC7236;
          .amenity_icon {
            padding: 1px;
            margin-right: 1.048rem;
            object-fit: contain;
            width: 100%;
            max-width: 1.5rem;
          }
        }
        .ongoing_location,
        .ongoing_area,
        .ongoing_space {
          @include textStyles(1.25em, normal, 0);
          margin: 1em 0;
          object-fit: contain;
          display: flex;
          .location_wrapper,
          .area_wrapper,
          .space_wrapper {
            min-width: 2em;
            max-width: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1em;
          }
          .location_icon {
            width: 100%;
            max-width: 1.4em;
            padding: 2px;
          }
          .area_icon {
            width: 100%;
            max-width: 1.806em;
            padding: 2px;
          }
          .space_icon {
            width: 100%;
            max-width: 1.805em;
            padding: 1px 0;
          }
        }
      }
    }
  }
  // .ongoing_project_container:nth-child(1) {
  // .ongoing_project_container:nth-child(1) {
  //   display: none;
  // }
  .arrow_container {
    position: absolute;
    top: 0;
    right: 30%;
    z-index: 2;
    background-color: $color-FFFFFF;
    cursor: pointer;
    padding: 2em 4em;
    .arrows_wrapper {
      display: flex;
      justify-content: space-between;
    }
    .ongoing-prev,
    .ongoing-next {
      margin: 0 2em;
      max-width: 3.75em;
    }
  }
  .arrows_wrapper:nth-child(1) .arrows_wrapper {
    display: none;
  }
}

.inner_sec5 {
  background-color: $color-F9F9F9;
  padding: 6.25em 0;
  .location_heading {
    @include textStyles(2.5em, normal, 0);
    font-family: "Libre Baskerville";
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 3.75rem;
  }
  .flex-center {
    align-items: center;
    .map_frame {
      .map {
        width: 100%;
        max-width: 60.813rem;
        height: 38.313rem;
        max-height: 38.313rem;
      }
    }
  }
  // .accordion_wrapper {
  //   .faq_item {
  //     padding: 2.3em 1.875em 2.3em 0;
  //     padding-right: 12.5em;
  //     position: relative;
  //     cursor: pointer;
  //     border-bottom: 1px solid $color-FFFFFF;
  //   }
  //   .faq_item.active {
  //     box-shadow: none;
  //   }
  //   .faq_item:first-child {
  //     padding: 0 1.875em 2.875em 0;
  //     .faq_open_close_logo {
  //       top: 0em;
  //     }
  //   }
  //   .faq_title {
  //     font-size: 1.5em;
  //     letter-spacing: 0;
  //     line-height: 1.5em;
  //     color: $color-FFFFFF;
  //     text-transform: uppercase;
  //   }
  //   .faq_desc {
  //     font-size: 1.3em;
  //     line-height: 1.6em;
  //     color: $color-FFFFFF;
  //     height: 0;
  //     overflow: hidden;
  //     // transition: transform 0.3s ease-out; // note that we're transitioning transform, not height!
  //     // transform: scaleY(0); // implicit, but good to specify explicitly
  //     // transform-origin: top;
  //     li {
  //       position: relative;
  //       padding-left: 1em;
  //       &::before {
  //         content: "";
  //         position: absolute;
  //         top: 0.6em;
  //         left: 0em;
  //         width: 8px;
  //         height: 8px;
  //         border-radius: 50%;
  //         background-color: $color-FFFFFF;
  //       }
  //     }
  //   }
  //   .faq_desc.active {
  //     margin-top: 1em;
  //     height: auto;
  //     // transform: scaleY(1);
  //   }
  //   .faq_open_close_logo {
  //     position: absolute;
  //     width: 100%;
  //     max-width: 3.351em;
  //     top: 2.875em;
  //     right: 0;
  //   }
  // }
}

.inner_sec6 {
  overflow: hidden;
  padding: 6.25em 0;
  background: url("https://via.placeholder.com/1920x725/808080/808080");
  .gallery_container {
    position: relative;
    margin-right: 0;
    padding-right: 0;
    .arrows_wrapper {
      display: none;
      position: absolute;
      top: -6rem;
      right: 8.813em;
      width: 100%;
      // display: flex;
      justify-content: flex-end;
      img {
        width: 100%;
      }
      .floor-prev {
        margin-right: 2.937em;
        cursor: pointer;
        max-width: 3.75em;
      }
      .floor-next {
        cursor: pointer;
        max-width: 3.75em;
      }
    }
    .arrows_wrapper.active {
      display: flex;
    }
  }
  // .gallery_container:nth-child(1),
  // .gallery_container:nth-child(2),
  // .gallery_container:nth-child(3),
  // .gallery_container:nth-child(4),
  // .gallery_container:nth-child(5) {
  //   display: none;
  // }
  .floor_heading {
    @include textStyles(2.5em, normal, 0);
    font-family: "Libre Baskerville";
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 3.75rem;
    color: $color-FFFFFF;
  }
  .text_container {
    max-width: 75%;
    .area_details {
      @include textStyles(1.875em, normal, 0);
      border-bottom: 1px solid $color-FFFFFF;
      color: $color-FFFFFF;
    }
    .area_wrap {
      padding: 1.5em 0;
      border-bottom: 1px solid $color-FFFFFF;
      .title {
        @include textStyles(1.25em, normal, 0);
        font-weight: 600;
        color: $color-FFFFFF;
        margin-bottom: 0.5rem;
      }
      .area {
        @include textStyles(1.25em, normal, 0);
        color: $color-FFFFFF;
        padding-left: 1em;
        ul {
          counter-reset: list;
          li {
            list-style: none;
            position: relative;
          }
          li::before {
            counter-increment: list;
            content: counter(list, disc) " ";
            position: absolute;
            left: -1em;
          }
        }

        // li {
        //   list-style-type: disc !important;
        //   margin: 0.5rem 0 0 1em;
        // }
      }
    }
  }
  .floor_slider {
    position: relative;
    margin-right: 2em;
    .image_wrapper {
      position: relative;
      .imageanimate {
        .floor_img {
          width: 100%;
        }
      }
      .download_icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }
      .image_title {
        text-align: center;
        @include textStyles(1.25em, normal, 0);
        color: $color-FFFFFF;
        margin-top: 0.5rem;
      }
    }
    .image_wrapper.active {
      transform: translateX(20%);
    }
  }
}

.inner_sec7 {
  overflow: hidden;
  padding: 6.375em 0 7.313em;
  background: url("https://via.placeholder.com/1920x725/808080/808080");
  .virtual_container {
    // position: relative;
    margin-right: 0;
    padding-right: 0;
  }
  .virtual_heading {
    @include textStyles(2.5em, normal, 0);
    font-family: "Libre Baskerville";
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 4rem;
    color: $color-FFFFFF;
  }
  .virtual_heading_1 {
    text-transform: capitalize;
  }
  .virtual_row {
    justify-content: center;
    position: relative;
    .virtual_col {
      position: relative;
      flex: 0 0 40%;
      padding: 0 1.6875em;
      // padding: 0 4.75em;
    }
    .video_thumbnail {
      position: relative;
      // width: 100%;
      // max-width: 52.563em;
      // height: 100%;
      // max-height: 33.938em;
    }
    .play_icon1 {
      position: absolute;
      top: 43%;
      right: 44%;
      width: 100%;
      max-width: 4.563em;
      // height: 100%;
      // max-height: 4.625em;
      cursor: pointer;
    }
    .virtual_text {
      text-align: center;
      font-size: 1.875em;
      color: #ffffff;
      margin-top: 0.533em;
    }
  }
}
@media (max-width: 1600px) {
  .inner_sec7 {
    .virtual_row {
      .virtual_col {
        //       padding: 0 9.75em;
        .video_thumbnail {
          width: 100%;
          max-width: 52.563em;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
  .inner_sec4 {
    .arrow_container {
      right: 31%;
    }
  }
}

@media (max-width: 1150px) {
  .inner_sec6 {
    .text_container {
      max-width: 85%;
    }
  }
}

@media (max-width: 1024px) {
  .inner_sec7 {
    .virtual_row {
      .virtual_col {
        .video_thumbnail {
          max-width: 45.563em;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .inner_sec6 {
    .text_container {
      max-width: 90%;
    }
  }
}

@media (max-width: 835px) {
  .inner_sec6 {
    .floor_slider {
      .image_wrapper.active {
        transform: translateX(0%);
      }
    }
  }

  .inner_sec6 {
    .floor_slider {
      margin-top: 3.5em;
    }
  }
  .inner_sec7 {
    .virtual_row {
      flex-direction: column;
      .virtual_col {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 3em;
        .video_thumbnail {
          max-width: 100%;
        }
        .play_icon1 {
          max-width: 6.563em;
          top: 44%;
          right: 46%;
        }
      }
    }
  }
  .inner_sec6 {
    .text_container {
      max-width: 65%;
    }
  }
}

@media (max-width: 600px) {
  .inner_sec1 {
    .my_breadcrumb {
      font-size: 1.4em;
    }

    .navigate_tabs {
      margin: 5em 0;
      padding-bottom: 1.5em;
      .nav_wrapper {
        // overflow: auto;
        .nav_link {
          width: 100%;
          white-space: nowrap;
          font-size: 1.8em;
        }
      }
      .nav_wrapper::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .inner_sec2 {
    .image_wrapper {
      margin-top: 2.5em;
      // text-align: center;
    }
    .text_container {
      max-width: 100%;
      // .location_icon {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }
      .project_name {
        margin-top: 0;
      }
      .project_desc {
        font-size: 1.5em;
        color: #222222;
        margin: 2.13333em 0 1.866666em;
      }
      .highlights_heading {
        font-weight: 600;
        font-size: 1.8em;
      }
      .project_feature {
        font-size: 1.5em;
        .project_feature_content {
          margin-left: 2.2em;
          font-size: 1.066666em;
        }
      }
    }

    .image_wrapper {
      .project_image {
        max-width: 30em;
      }
      .circl_wrapper {
        top: 4.5em;
        right: 1.5em;
        // right: 0;
        .project_image {
          max-width: 10em;
        }
      }
    }
  }

  .inner_sec3 {
    padding: 4em 0 9em;
    // .swiper-slide {
    // margin-right: 0 !important;
    // }
    .gallery_heading {
      font-size: 2.5em;
      margin-top: 0;
      margin-bottom: 1em;
    }

    .image_wrapper {
      .imageanimate {
        max-width: 100%;
        margin: 0;
        padding: 0;
        .gallery_img {
          max-width: 100%;
          width: 90%;
        }
      }
      .title_text {
        // right: 1.2em;
        right: 1.6em;
        font-size: 1.4em;
      }
    }

    .gallery_container {
      .arrows_wrapper {
        top: auto;
        bottom: -18%;
        right: 14em;

        z-index: 1;
      }
    }
  }

  .inner_sec4 {
    .ongoing_project_container {
      margin: 2.5em 0 1em;
      .swiper .swiper-slide {
        height: auto;
      }
      .ongoing_box {
        height: 100%;
        .image_container img {
          // .ongoing_project {
          // height: 100%;
          min-height: 26em;
          display: block;
          width: 100%;
          height: 100%;
          // }
        }
        .ongoing_proj_text {
          margin: 0 1.5em;
          .ongoing_heading {
            font-size: 2.5em;
          }
          .ongoing_desc {
            font-size: 1.5em;
            margin: 2.13333em 0 1.866666em;
          }
        }
        .image_container {
          margin: 0 1.5em;
        }
      }
    }
    .arrow_container {
      position: static;
      // top: auto;
      // bottom: 6em;
      // right: 19%;
      background-color: #00000000;
      z-index: 1;
      .arrows_wrapper {
        justify-content: center;
      }

      img {
        margin: 0 2em !important;
      }
    }

    .cta_wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 3em;
      .right_arrow_cta_flex {
        .cta_right_arrow {
          padding-left: 7em;
        }
      }
    }
  }

  .inner_sec5 {
    padding: 4em 0;
    .location_heading {
      margin-bottom: 1.5em;
    }
    .map_frame {
      height: 30em;
      margin-bottom: 3.5em;
      .map {
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
    .faq_item {
      .faq_open_close_logo {
        max-width: 1.5em;
        height: 1em;
        margin-top: 0.4em;
      }
    }
  }

  .inner_sec6 {
    padding: 4em 0 14em;
    .floor_heading {
      margin-bottom: 1.5em;
    }

    .col-lg-3 {
      padding-left: 0;
    }
    .text_container {
      max-width: 100%;
      margin: 0 5em;
      margin-bottom: 5em;
      text-align: center;

      .area_details {
        padding-bottom: 1em;
      }
      .area_wrap {
        .title,
        .area {
          font-size: 1.7em;
        }
        .area {
          margin-top: 0.5em;
        }
      }
    }

    .col-lg-9 {
      padding-left: 0;
    }
    .swiper-slide {
      max-width: 100%;
    }

    .floor_slider {
      margin: 0 1.5em;
      .image_wrapper.active {
        transform: translateX(5%);
      }
    }

    .gallery_container {
      .arrows_wrapper {
        z-index: 1;
        top: auto;
        bottom: -45%;
        right: 13em;
      }
    }
  }

  .inner_sec6.active {
    padding-bottom: 5em;
  }
  .inner_sec7 {
    padding: 4.375em 0 5.313em;
    .virtual_heading {
      margin: 0 0 1.5rem;
    }
    .virtual_heading_1 {
      display: block;
    }
    .virtual_row {
      .virtual_col {
        padding: 1.5em 3em;
        .play_icon1 {
          top: 44%;
          right: 43%;
          max-width: 4em;
        }
      }
    }
    .swiper-slide {
      max-width: 100%;
    }

    .virtual_slider {
      margin: 0 1.5em;
    }

    .gallery_container {
      .arrows_wrapper {
        z-index: 1;
        top: auto;
        bottom: -45%;
        right: 13em;
      }
    }
  }
}

@media (max-width: 400px) {
  .inner_sec3 {
    .image_wrapper {
      .title_text {
        // right: 1.075em;
        right: 1.4em;
      }
    }
  }
}
