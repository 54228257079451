@import "../../common.scss";

.main_container {
  box-sizing: border-box;

  .heading_content {
    max-width: 100%;
    margin-top: 9.375em;
    margin-left: 8.75em;
    margin-right: 8.75em;
    display: flex;
    justify-content: center;

    .text {
      width: 80em;

      .page_title {
        // font-family: $heading-basker-regular;
        // font-size: 2.5em;
        @include textStyles(2.4em, normal, 0);
        font-weight: 600;
        letter-spacing: 0px;
        text-align: center;
        font-family: $heading-basker-regular;
        text-transform: uppercase;
        // text-align: center;
        // color: #a7a7a7;
        color: #000;
      }
    }
  }

  .info_content {
    max-width: 100%;
    margin-top: 9.375em;
    margin-left: 8.75em;
    margin-right: 8.75em;
    .info_container {
      display: flex;
      justify-content: center;

      .text {
        width: 47.688em;
        margin-right: 9.375em;

        .info {
          .title {
            width: 100%;
            margin-bottom: 1.25em;
            h2 {
              @include textStyles(2.5em, normal, 0);
              font-weight: 600;
              text-align: left;
              font-family: $heading-basker-regular;
              text-transform: uppercase;
              // font-size: 2.2em;
              // font-weight: 500;
            }
          }
          .description {
            p {
              font-family: "Poppins", Regular;
              font-size: 1.25em;
              line-height: 1.8em;
              color: #58595b;
              margin: 1.25em 0;
            }
          }
        }

        .key_features {
          width: 100%;

          .feature_title {
            margin-top: 3.5em;
            margin-bottom: 2em;
            h4 {
              font-size: 1.563em;
            }
          }

          .features_list {
            width: 100%;
            .list {
              li {
                display: flex;
                align-items: center;
                margin: 1.5em 0;
                font-family: Poppins, Medium;
                font-size: 1.4em;
                color: #58595b;
                font-weight: 500;

                .feature_icon {
                  width: 3em;
                  margin-right: 0.4em;
                  margin-left: -0.3em;

                  img {
                    max-width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .image {
        width: 53em;
        height: auto;
        position: relative;
        img {
          max-width: 100%;
          max-height: 100%;
        }
        .sticker {
          position: absolute;
          top: 4.063em;
          right: -5.7em;
          width: 12em;
          height: 12em;
          border-radius: 50%;
          background-color: #dc7236;
          text-align: center;
          color: white;

          .text {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .content {
              p {
                font-size: 1.563em;
              }
              h1 {
                font-size: 3.563em;
              }
            }
          }
        }
      }
    }
  }

  .feature_container {
    // margin: 0.5em 7.9em 5em;
    margin: 0 0 5em;
    .feature_list1 {
      margin: 2em 0 2em;
      h4 {
        font-size: 1.563em;
      }
    }
    .feature_desc {
      p {
        font-family: "Poppins", Regular;
        font-size: 1.25em;
        line-height: 1.8em;
        color: #58595b;
        margin: 1.25em 0;
      }
    }
  }

  .team_content {
    max-width: 100%;
    margin-top: 4em;
    margin-left: 8.75em;
    margin-right: 8.75em;

    .team_container {
      position: relative;
      .team_title {
        width: 100%;
        text-align: center;

        h2 {
          font-family: Baskerville, Regular;
          font-size: 2.5em;
        }
      }
      .team_description {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 3em 0;
        color: #58595b;

        p {
          width: 65em;
          text-align: center;
          line-height: 25px;
          font-size: 1.25em;
        }
      }

      .team_members {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .swiper {
          padding-bottom: 1em;
        }
        .member_info {
          .member_info_content {
            padding: 2.125em 4.375em;
            text-align: center;

            .image {
              width: 100%;
              display: flex;
              justify-content: center;
              .image_content {
                width: 12.3em;
                border-radius: 6em;

                img {
                  max-width: 100%;
                  opacity: 50%;
                }
              }
            }

            .text {
              h4 {
                margin: 0.7em 0;
                font-size: 1.6em;
              }
              h6 {
                font-size: 1em;
                color: #58595b;
              }
              p {
                margin: 1em 0;
                font-size: 1.1em;
                line-height: 25px;
                color: #58595b;
              }
            }
          }
        }

        .swiper-slide:hover {
          background-color: #f9f9f9;
          border-radius: 15px;
          box-shadow: 0 3px 6px #0000001a;

          .image {
            .image_content {
              position: relative;
              img {
                opacity: 100%;
              }
            }
            .image_content::after {
              content: "";
              width: 12.3em;
              height: 12.3em;
              position: absolute;
              left: 0;
              right: 0;
              background-color: #dc7236;
              border-radius: 6em;
              opacity: 60%;
            }
          }

          .text {
            h4 {
              color: #dc7236;
            }
          }
        }
      }

      .team_arrow {
        position: absolute;
        left: 34%;

        .team_arw {
          margin: 0 2em;
          max-width: 100%;
          height: 3em;
        }
      }
    }
  }

  .testimony_content {
    max-width: 100%;
    margin-top: 9.375em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../images/About/testimony_background.jpg");
    background-size: cover;

    .testimony_container {
      width: 100%;
      // height: 36em;

      padding: 3em 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #1e1e1ee3;

      .testimony_info {
        position: relative;
        position: relative;
        width: 100%;
        max-height: 100%;
        text-align: center;
        color: white;
        .testimony_title {
          font-family: Baskerville, Regular;
          margin: 2.5em 0;
          width: 100%;
          h1 {
            font-size: 2.5em;
          }
        }
        .testimony_map {
          width: 100%;
          max-height: 100%;
          display: flex;
          overflow-y: auto;

          .testimony_data {
            .text {
              margin: 1.5em 2em;
              display: flex;
              justify-content: center;
              align-items: center;

              .testimony_description {
                width: 65em;
                margin: 0 8em;
                p {
                  font-size: 1.2em;
                  line-height: 25px;
                  color: rgba(211, 211, 211, 0.432);
                }
              }
            }
          }
          .seperator {
            margin: 1.5em 0;
            width: 100%;
            display: flex;
            justify-content: center;
            .horizontal_line {
              width: 50px;
              height: 1px;
              background-color: #dc7236;
            }
          }

          .client_name {
            width: 100%;
            text-align: center;

            h6 {
              font-size: 1.12em;
            }
            p {
              margin: 0.3em 0;
              font-size: 0.9em;
            }
          }
        }

        .testimony_map::-webkit-scrollbar {
          display: none;
        }

        .arrow_container {
          width: 100%;
          position: absolute;
          top: 48%;
          z-index: 100;
          .arrows_wrapper {
            width: 100%;
            margin-bottom: 1em;
          }

          .arrow_desktop {
            img {
              width: 100%;
              max-width: 3.5em;
              cursor: pointer;
            }
            .leftarrowimg {
              margin-right: 75%;
            }
          }
        }
        .arrow_mobile {
          img {
            margin-top: 4%;
            width: 100%;
            max-width: 4em;
          }

          .leftarrowimg {
            margin-right: 5%;
          }
        }
      }
    }
  }
}
@media (min-width: 2300px) {
  .main_container {
    .feature_container {
      margin: 0 7.25em 5em;
    }
  }
}

@media (max-width: 1300px) {
  .main_container {
    .team_content {
      .team_container {
        .team_members {
          .member_info {
            width: auto;
            margin: auto;
            .member_info_content {
              padding-left: 1.5em;
              padding-right: 1.5em;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 834px) {
  .main_container {
    .info_content {
      .info_container {
        display: block;
        .text {
          width: 100%;
          max-width: 100%;
          margin-right: 15px;
        }
      }
    }
    .team_content {
      .team_container {
        .team_members {
          .member_info {
            width: auto;
            margin: auto;
            .member_info_content {
              padding-left: 1em;
              padding-right: 1em;
              .text {
                p {
                  font-size: 1.1em;
                  line-height: 1.6em;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .main_container {
    .heading_content {
      margin: 3.4em 0;
      width: 100%;
      .text {
        .page_title {
          margin: 0 1em;
          font-size: 2.5em;
        }
      }
    }

    .info_content {
      margin: 0;
      .info_container {
        display: inline-block;
        width: 100%;
        .text {
          width: 100%;

          .info {
            margin-top: 1em;

            .title {
              width: 100%;
              h2 {
                margin: 0 0.5em;
                font-size: 2.5em;
              }
            }
            .description {
              width: 100%;
              margin: 3.2em 0 2.8em;
              p {
                margin: 0 1em;
                font-size: 1.5em;
                text-align: left;
                margin-bottom: 1em;
              }
            }
          }

          .key_features {
            width: 100%;
            margin-top: 4em;
            .feature_title {
              // margin: 0 1.5em;
              margin: 0 1.5em 0.5em;
              h4 {
                font-size: 2em;
              }
            }
            .features_list {
              margin: 0 1.5em;
              .list {
                li {
                  margin: 1em 0;
                  font-size: 1.6em;
                  .feature_icon {
                    width: 3.5em;
                  }
                }
              }
            }
          }
        }
        .image {
          width: 30em;
          max-height: 48em;
          margin: 0 1.5em;
          margin-top: 3em;
          .sticker {
            right: -4em;
            width: 8em;
            height: 8em;
            .text {
              .content {
                p {
                  font-size: 1em;
                }
                h1 {
                  font-size: 2.5em;
                }
              }
            }
          }
        }
      }
      .feature_container {
        margin: 0 1.5em 2em;
        .feature_list1 {
          h4 {
            font-size: 2em;
          }
        }
        .feature_desc {
          p {
            font-size: 1.5em;
            text-align: left;
            margin-bottom: 1em;
          }
        }
      }
    }
    .team_content {
      margin: 5em 0;
      height: auto;
      .team_container {
        .team_description {
          margin: 3.2em 0 2.8em;
        }
        height: auto;
        .team_title {
          h2 {
            font-size: 2.5em;
          }
        }
        .team_arrow {
          position: relative;
          // left: 31%;
          // bottom: 3%;
          left: 0;
          z-index: 1;
          text-align: center;
        }

        .team_description {
          p {
            margin: 0 1em;
            font-size: 1.5em;
            line-height: 25px;
          }
        }
        .team_members {
          overflow-y: auto;
          margin: 3em 2em;
          width: 90%;
          height: auto;
          // max-height: 100%;

          .member_info {
            width: auto;
            margin: 3em 2em;
            .member_info_content {
              max-width: 100%;
              padding: 0;

              .text {
                p {
                  font-size: 1.5em;
                  margin-bottom: 1.6em;
                }
              }
            }
          }
          .swiper-slide.swiper-slide-active {
            background-color: #f9f9f9;
            border-radius: 15px;
            box-shadow: 0 3px 6px #0000001a;
            // height: 55em;
            height: auto;

            .image {
              .image_content {
                position: relative;
                img {
                  opacity: 100%;
                }
              }
              .image_content::after {
                content: "";
                width: 12.3em;
                height: 12.3em;
                position: absolute;
                left: 0;
                right: 0;
                background-color: #dc7236;
                border-radius: 6em;
                opacity: 60%;
              }
            }

            .text {
              h4 {
                color: #dc7236;
              }
            }
          }
        }
        .team_members::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .testimony_content {
      height: auto;
      margin-top: 5em;
      .testimony_container {
        padding-top: 2.5em;
        .testimony_info {
          .testimony_map {
            .client_name h6 {
              font-size: 1.6em;
              opacity: 0.8;
            }
            .client_name p {
              font-size: 1.4em;
              opacity: 0.7;
              margin-bottom: 0.625em;
            }
            .testimony_data {
              .text {
                .testimony_description {
                  margin: 0;
                }
              }
            }
          }
          .arrows_wrapper {
            margin: 3em 0 2em;
          }
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .main_container {
    .team_content {
      .team_container {
        .team_members {
          .swiper-slide.swiper-slide-active {
            height: 60em;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .main_container {
    .team_content {
      .team_container {
        .team_members {
          .swiper-slide.swiper-slide-active {
            height: 62em;
          }
        }
        .team_arrow {
          left: 28%;
          bottom: 3%;
        }
      }
    }
  }
}
