@import "../../common.scss";
// .row{
//   justify-content: center;
// }
.projects_sec1 {
  .project_status_wrapper {
    display: flex;
    justify-content: center;
    margin: 7.313em 0 4em;
    .status_tab {
      @include textStyles(2em, normal, 0);
      // font-weight: 600;
      margin: 0 1em;
      cursor: pointer;
    }
    .status_tab.active {
      color: $color-DC7236;
      border-bottom: 1px solid $color-DC7236;
      font-weight: 600;
    }
  }


  .project_flex:nth-child(2){
    margin: 0 3em;
  }

  .projects_wrapper {
    height: 0;
    transition: transform 0s ease-out; // note that we're transitioning transform, not height!
    transform-origin: top;
    transform: scaleY(0); // implicit, but good to specify explicitly
    align-items: flex-start;
  }
  .projects_wrapper.active {
    height: auto;
    transform: scaleY(1);
  }
  .project_box {
    margin-bottom: 5em;
    display: none;
    max-width: 100%;
    img {
      max-width: 30.625em;
    }
    .project-title {
      @include textStyles(1.375em, normal, 0);
      font-weight: 500;
      margin: 0.5em 0 0;
      text-transform: uppercase;
      color: #000;
    }
    .project-desc {
      @include textStyles(1.25em, normal, 0);
      font-weight: 500;
      margin: 0.5em 0 1em;
      text-transform: uppercase;
      .location_icon {
        margin-right: 0.38em;
        max-width: 0.74em;
      }
    }
  }

  .project_box.active {
    display: block;
  }
  
}

@media (max-width: 2048px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 35em;
      }
    }
    .project_flex:nth-child(2){
      margin: 0 13em;
    }
  }
  
}

@media (max-width: 1920px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 30.6em;
      }
    }
    .project_flex:nth-child(2){
      margin: 0 3em;
    }
  }
  
}

@media (max-width: 1680px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 27em;
      }
    }
  }
  
}

@media (max-width: 1600px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 29em;
      }
    }
    .project_flex:nth-child(2){
      margin: 0;
    }
  }
}

@media (max-width: 1536px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 33em;
      }
    }
  }
  
}

@media (max-width: 1440px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 31em;
      }
    }
  }
}

@media (max-width: 1367px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 30em;
      }
    }
  }
  
}

@media (max-width: 1280px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 34em;
      }
    }
  }
}

@media (max-width: 1160px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 30em;
      }
    }
    .project_flex:nth-child(2){
      margin: 0 2.25em;
    }
  }
}

@media (max-width: 1080px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 28em;
      }
    }
    .project_flex:nth-child(2){
      margin: 0 2.5em;
    }
  }
}

@media(max-width:992px){
  .projects_sec1{
    .project_flex:nth-child(2){
      margin: 0 1.5em;
    }
  }
}

@media (max-width: 990px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 22em;
      }
    }
  }
}

@media(max-width:835px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 30.5em;
      }
    }
    .project_flex:nth-child(2){
      margin: 0 6.5em;
    }
  }
}

@media(max-width:834px){
  .projects_sec1{
    .project_box{
      img{
        max-width: 27.5em;
      }
    }
    .project_flex:nth-child(2){
      margin: 0 0.5em;
    }
  }
}

@media (max-width: 600px){
  .projects_sec1{
    .project_status_wrapper{
      margin-top: 4em;
      width: 100%;
      flex-direction: column;
      align-items: center;
      .status_tab{
        margin-top: 2em;
        width: 70%;
      }
    }
    .project_box{
      img{
        max-width: 100%;
      }
      .project-title{
        font-size: 1.5em;
      }
      .project-desc{
        font-size: 1.5em;
      }
    }
  }
}
