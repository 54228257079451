@import "../../common.scss";

.form_section {
  margin: 7em 0 7.4em;
  .error_message {
    bottom: -2em;
  }
  .error_message_1 {
    bottom: -2em;
  }
  .form_heading {
    margin: 0 0 1em 0;
    font-size: 2.5em;
    font-family: "Libre Baskerville Bold";
    font-weight: 600;
  }
  .form_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .form_field_wrapper {
      margin-top: 1.5em;
    }
    .form_field_customwidth {
      position: relative;
      width: 100%;
      max-width: 30%;
    }
    .form_field_textarea {
      width: 100%;
      max-width: 85%;
    }
    .form_field_customwidth_submit {
      width: 100%;
      max-width: 80%;
    }
    .mobile_number_box {
      padding: 0;
      .country_code {
        max-width: 30%;
      }
      .mobile_number {
        max-width: 68%;
      }
    }
    .form_field_customwidth_submit_cta {
      width: 100%;
      max-width: 15%;
      padding: 1.263em 0 0 1em;
      // margin-bottom: -0.8em;
      .cta_wrapper {
        display: flex;
        justify-content: flex-end;
        .project-button {
          padding-bottom: 0.4em;
          &::after {
            height: 1.5px;
          }
        }
      }
    }
    .textarea {
      padding-bottom: 0;
    }
  }
}

@media (max-width: 1080px) {
  .form_section {
    .form_flex {
      .form_field_wrapper {
        margin-top: 3em;
      }
      .mobile_number_box {
        padding: 0;
        .country_code {
          max-width: 40%;
          .countrycode_select {
            padding-left: 0.5em;
          }
        }
        .mobile_number {
          max-width: 58%;
        }
        .error_message_1 {
          bottom: -3.5em;
        }
      }
    }
  }
}
@media (max-width: 992px) {
  .form_section {
    .form_flex {
      .form_field_textarea {
        max-width: 80%;
      }
    }
  }
}
@media (max-width: 800px) {
  .project-button {
    .right-arrow {
      padding-left: 1.375em !important;
    }
  }
}
@media (max-width: 766px) {
  .form_section {
    .form_flex {
      // .form_field_wrapper {
      //   margin-top: 2em;
      // }
      .mobile_number_box {
        .error_message_1 {
          // bottom: -4.5em;
          font-size: 1.15em;
        }
      }
    }
    .error_message {
      // bottom: -3em;
      font-size: 1.15em;
    }
  }
}

@media (max-width: 600px) {
  .form_section {
    margin: 5em 0 5em;
    .form_heading {
      margin: 0 0 .48em 0;
    }
    .form_flex {
      .form_field_wrapper {
        width: 100%;
        max-width: 100%;
      }
      .form_field_customwidth,
      .form_field_textarea {
        max-width: 100%;
      }
      .form_field_customwidth_submit_cta {
        max-width: 100%;
        display: flex;
        justify-content: center;
        padding-left: 0;
        margin-top: 2.5em;
      }
      .mobile_number_box {
        .country_code {
          max-width: 30%;
        }
        .mobile_number {
          max-width: 68%;
        }
      }
    }
  }
  .project-button {
    .right-arrow {
      padding-left: 3.375em !important;
    }
  }
  .form_section {
    .form_flex {
      .mobile_number_box {
        .error_message_1 {
          font-size: 1.3em;
          bottom: -1.8em;
        }
      }
    }
    .error_message {
      font-size: 1.3em;
      bottom: -1.8em;
    }
  }
}

@media (max-width: 391px) {
  .form_section {
    .form_flex {
      .form_field_wrapper {
        margin-top: 3em;
      }
      .mobile_number_box {
        .error_message_1 {
          bottom: -3.2em;
        }
      }
    }
    .error_message {
      bottom: -1.8em;
    }
  }
}
