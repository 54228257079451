.my_container {
  .faqs_content {
    .title {
      width: 100%;
      margin-top: 6.4em;
      margin-bottom: 2.375em;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-family: Baskerville, Regular;
        font-size: 2.5em;
      }
    }

    .div_line {
      width: 100%;
      height: 1px;
      background-color: #cbcbcb;
      margin-top: 2em;
    }

    .accordion-header {
      position: relative;
      width: 100%;
      margin: 1em 0;

      .collapsed {
        .icon {
          position: absolute;
          right: 0;
          width: 100%;
          max-width: 17px;
        }
      }

      .accordion-button.collapsed {
        .icon {
          transform: rotate(0deg);
        }
      }

      .accordion-button {
        width: 90%;
        display: flex;
        align-items: center;
        width: 100%;
        font-family: Poppins, Medium;
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        letter-spacing: 0.48px;
        color: #1a1818;
        background-color: white;
        border: none;

        p {
          width: 85%;
        }

        .icon {
          transform: rotate(180deg);
          transition: 0.3s ease-in-out;
          position: absolute;
          right: 0;
          max-width: 17px;
        }
      }
    }

    .accordion-body {
      width: 90%;
      text-align: left;
      margin-left: 1em;
      font-size: 14px;
      letter-spacing: 0.4px;
      // .emi_cal {
      //     margin-top: 3em;
      // }

      .faq_list {
        margin-left: 2em;

        li {
          list-style-type: disc !important;
        }
      }
    }
  }
  .emi-cal {
    .cal-btn {
      margin: 0 auto;
      text-align: center;
      cursor: default;
      &:hover {
        &::after {
          width: 110%;
        }
      }
      .cal-link {
        color: #dc7236;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  // #ecww-widgetwrapper {
  //     padding-bottom: 0 !important;
  //   }

  //   #ecww-widget {
  //     position: relative;
  //     padding-top: 0;
  //     padding-bottom: 280px;
  //     height: 0;
  //     overflow: hidden;
  //   }
}

@media (max-width: 600px) {
  .my_container {
    .emi-cal {
      .cal-btn {
        margin: 0;
        .right-arrow {
          padding-left: 1.375em !important;
        }
      }
    }
  }
}
