@import "../../common.scss";

.common-banner {
  .banner-background-wrapper {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    appearance: none;
    -webkit-appearance: none;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100vh;
      // background: rgba(0, 0, 0, 0.2);
    }
    .banner-img {
      width: 100%;
      max-width: 100%;
      height: 100vh;
    }
    .banner-link-wrapper {
      background-color: $color-FFFFFF;
      position: absolute;
      left: 0;
      bottom: 0;
      box-shadow: 0px 0.4px 0.4px rgba($color: $color-000000, $alpha: 0.4);
      padding: 2.188em 6.375em 2.188em 5em;
      display: flex;
      align-items: center;
      .banner-text-container {
        .banner-link-heading {
          font-family: "Poppins";
          font-weight: 500;
        }
        .banner-link-desc {
          font-family: "Poppins";
          font-weight: normal;
        }
      }
      .arrow-img {
        display: inline;
        margin-left: 6.875em;
        max-width: 4.125em;
      }
    }
  }

  .banner-text-wrapper {
    position: absolute;
    top: 37vh;
    display: block;
    color: $color-FFFFFF;
    .banner-caption {
      font-family: "Poppins";
      text-transform: uppercase;
      font-weight: 300;
      font-style: normal;
      font-size: 1.875em;
      margin-bottom: 1em;
    }
    .banner-title {
      font-size: 5.625em;
      text-transform: uppercase;
      font-family: "Gelasio";
      font-weight: bold;
      font-style: normal;
      max-width: 100%;
      margin-right: 1.5em;
    }
  }
}
@media (max-width: 1400px) {
  .common-banner {
    .banner-background-wrapper {
      height: 97.5vh;
      &:before {
        height: 97.5vh;
      }
      .banner-img {
        height: 97.5vh;
      }
    }
  }
}
@media (max-width: 1367px) {
  .common-banner {
    .banner-text-wrapper {
      .banner-title {
        font-size: 5em;
      }
    }
  }
}
@media (max-width: 992px) {
  .common-banner {
    .banner-text-wrapper {
      top: 40vh;
      .banner-caption {
        font-size: 1.6em;
      }
      .banner-title {
        font-size: 2.8em;
      }
    }
  }
}

@media (max-width: 600px) {
  .common-banner {
    .banner-background-wrapper {
      height: 90vh;
      &:before {
        height: 90vh;
      }
      .banner-img {
        height: 90vh;
      }
      .banner-link-wrapper {
        display: none;
      }
    }
    .banner-text-wrapper {
      max-width: 95%;
      top: 35vh;
      .banner-title {
        max-width: 100%;
        line-height: 1.6;
      }
    }
  }
}
